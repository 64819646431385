import React, { Component } from 'react';

import {
  Link
} from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark justify-content-between">
          <div className="container">
            <Link to='/' className='logo-text' style={{color: "orange"}}>Alec Sherwood</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerHeader" aria-controls="navbarTogglerHeader" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerHeader">
              <ul className='navbar-nav ml-auto mt-2 mt-lg-0'>
                <li className="nav-item">
                  <Link to='/code' className="custom-link nav-link">Code</Link>
                </li>
                <li className="nav-item">
                  <Link to='/currentProject' className="nav-link custom-link">Current Project</Link>
                </li>
                <li className="nav-item">
                  <Link to='/music' className="nav-link custom-link">Music</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
