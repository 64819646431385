import React, { Component } from 'react';
import Bass from "../../../assets/images/bass_outside.jpg";
import './music.css'

class Music extends Component {
  render() {
    return (
      <div className='content-page container container-fluid'>
        <div className='row pt-sm-5'>
          <div className='col-sm pr-sm-5'>
            <div className='row'>
              <div className="col-sm">
                <div className="music-container">
                  <h1>Music</h1>
                  <p>I have been playing music in some capacity for most of my life. I am a multi-instrumentalist with a primary focus on guitar and bass. I have also spent a lot of time learning music production and composition. I really enjoy how sdongs are written and composed and enjoy creating my own music.</p>
                  <p>My current musical pursuits have been in creating music and sound design for game development projects. I have found that my appreciation for instrumental music matches up nicely with my interest in game development.</p>
                </div>
                <div className="row code-row-2-margin-top">
                  <div className="col-sm">
                    <h2>Favorite Genres</h2>
                    <ul>
                      <li>Indie Rock</li>
                      <li>Progressive Metal</li>
                      <li>Orchestral / Symphonic</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm'>
            <img src={Bass} className="img img-fluid" />
          </div>
        </div>
      </div>
    );
  }
}

export default Music;
