import { Component } from 'react';

//Impprt Router
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import React from 'react';

// CSS Imports
import './assets/css/App.css';

//Component Imports
import Header from './components/header/header';
import Footer from './components/footer/footer';

//Page Imports
import Home from "./components/pages/home/home";
import Code from './components/pages/code/code';
import CurrentProject from "./components/pages/currentProject/currentProject";
import Music from './components/pages/music/music';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
            <Route exact path='/' component={Home} />
            <Route exact path='/code' component={Code} />
            <Route exact path='/currentProject' component={CurrentProject} />
            <Route exact path='/music' component={Music} />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
