import React, { Component } from 'react';
import professional_portrait from '../../../assets/images/professional_portrait.jpg';
import { Link } from 'react-router-dom';

class Code extends Component {
  render() {
    return (
      <div className='content-page container container-fluid'>
        <div className='row pt-sm-5'>
          <div className='col-sm pr-sm-5'>
            <div className='row'>
              <div className="col-sm">
                  <h1>Code</h1>
                  <p>I am a Software Engineer working in a few problem spaces ranging from DevOps and developer tooling to game development. I view myself as a software generalist with a strong motivation to learn new technologies and explore new problems to solve.</p>
                <p> My career has had a largely DevOps/developer tooling and infrastructure focus. However I have done full stack development as well as mobile development over the course of my career. </p>
                  <p>To learn more about my projects see the information below.</p>
                <h2 className="code-row-2-margin-top">Current Prjects</h2>
                <p>I am currently working on the following prjoects:</p>
                <ul>
                  <li><b>Glider Golf</b> I have been developing a game called Glider Golf. Check out my <Link to="/currentProject">current project</Link> page for more information.</li>
                  <li><b>This Website:</b> I maintain this portfolio website as a way to keep up with JS and React</li>
                </ul>
              </div>
              <div className='col-sm'>
                <img src={professional_portrait} className="img img-fluid" />
            </div>
          </div>
          <div className='row'>
            <div className="col-sm pr-sm-5 offset-lg-6 code-row-2-margin-top">
              <h2>Contact</h2>
              <p> To get a deeper look at what I am working on check out my <a href="https://github.com/alecsherwood">Github</a>. Also please feel free to connect with me on <a href="https://www.linkedin.com/in/alec-sherwood-73a01469/">Linkedin</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Code;
