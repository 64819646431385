import React from 'react';
import {Component} from 'react';
import {Link} from "react-router-dom";

class Home extends Component {

  getTime = () => {
    const date = new Date();
    return date.getHours();
  }

  setGreeting = (timeOfDay: number) => {
    if(typeof timeOfDay === 'undefined'){
      return 'Welcome';
    }
    else if(timeOfDay < 12){
      return 'Good Morning';
    }
    else if(timeOfDay >= 12 && timeOfDay < 17){
      return 'Good Afternoon';
    }
    else if(timeOfDay >= 17){
      return 'Good Evening';
    }
  }

  render() {
    return (
      <div className=''>
        <div className='landing'>
          <div className='row vh-center'>
            <div className='all-center col-sm'>
                <h1 className='display-3 align-middle' style={{color: 'white'}}>{ this.setGreeting(this.getTime()) }</h1>
                <h2 className='lead' style={{color: 'black'}}>My name is Alec! It's nice to meet you!</h2>
                <Link to="/currentProject">
                  <button className="btn btn-lg btn-dark">See what I'm working on!</button>
                </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
