import React from "react";
import {Component} from "react";
import gliderGolfScreenshot from "../../../assets/images/GliderGolfScreenshot.jpg";

class currentProject extends Component<any, any>{
    render() {
        return (
            <div className="content-page container container-fluid">
                <div className="row code-row-2-margin-top">
                    <div className="col-sm col-xs-12 col-6 col-offset-2">
                        <h1 className="text-center">Glider Golf</h1>
                    </div>
                </div>
                <div className="row">
                    <img src={gliderGolfScreenshot} height="100%" width="100%" alt="Screenshot from a glider golf hole."/>
                </div>
                <div className="row code-row-2-margin-top">
                    <p><b> Project Description: </b>Glider Golf is a prototype game built to incorporate the
                        gameplay mechanics of disc golf, traditional golf with the physics mechanics of a glider plane.
                    </p>
                    <p><b> Core Gameplay Mechanics: </b> The player would operate a
                        semi-powered glider to reach a target from a starting position. The player would activate a
                        engine mechanism to provide thrust and the initial lift off of the start point. From there they
                        would steer the glider towards the target.
                        this initial thrust would count as a stroke. If the player lands or crashes the glider their glider
                        would be reset at or near the position of the crash and they would be able to launch again for an additional stroke on their score.
                        The goal being to reach the target in as few
                        strokes as possible as is the goal in disc and traditional golf.</p>
                    <p>To reach the goal the player will need to avoid physical obstacles as well as air currents that
                        could negatively impact the gliders trajectory.
                        As a glider can reach heights significantly higher than a disc or a golf ball can,
                        the wind currents were added to provide additional navigational challenges while the glider is higher off the ground.
                        The dynamic wind current system adds two interesting things mechanically. The first being that it adds a challenge while the glider is in the air to avoid counter productive wind currents.
                        it also allows for more interesting ways to get to a target as not all wind currents would negatively impact the glider trajectory. The idea of a flow-state where a player could get a hole in one to be very intriguing.
                    </p>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <p><b>Project Goals:</b></p>
                        <ul>
                            <li>Gain experience using Unreal Engine and Blueprints/C++ to build a complex
                                game.
                            </li>
                            <li>Provide a use case for creating multimedia assets (3d Models,
                                audio, etc)
                            </li>
                        </ul>
                        <p><b>What I have Learned:</b></p>
                        <ul>
                            <li>
                                <b>Visual Scripting: </b> This is a very helpful way of interacting with highly iterative parts of game development. However once the scripts reached a certain level of complexity I found that I wanted to use a traditional programming language.
                                The learning here being that both Visual Scripting and traditional languages are needed in order to have an effective project. While you can fully implement a game in either, you are leaving a lot on the table if you aren't using both.
                            </li>
                            <li>
                                <b>Engine & Mathematics</b> One of the biggest takeaways from this project is that the engine (in my case Unreal Engine) is a lot more approachable than I first thought it would be.
                                It's a very powerful tool but a lot of that power can be abstracted away and only brought back to the forefront as you need it.
                                I found this to be true of the math and physics components of this project as well.
                            </li>
                            <li>
                                <b>3D Modeling & Design</b> I really enjoyed learning how to 3d model for this project. I enjoyed learning about solid surface modeling in blender and sculpting with ZBrush. I was also able to do some 3d painting using Adobe's Substance Painter.
                                While I have a lot more to learn in the space and I intentionally picked a simplistic art style, I found I was able to quickly get an asset built and in the game fairly easily.
                                The biggest learning here was to ensure that your model is as you need it before adding it to your game as manipulating the model in engine can be cumbersome and more workk than is necessary.
                                Very important to get the model working and  good before importing. That said if needed the engine is very capable of fixing issues with models.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default currentProject;